





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ExportHisData } from "@/request/differences";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends Vue {
  private filters: any = {
    name: "",
    type: "",
    startTime: null,
    endTime: null,
  };
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.endTime) {
          let yearTime: any = new Date(self.filters.endTime);
          yearTime.setFullYear(yearTime.getFullYear() - 1);
          yearTime = new Date(yearTime).getTime() - 86400000;

          return (
            time.getTime() > new Date(self.filters.endTime).getTime() ||
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime() 
            // ||time.getTime() < yearTime
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime()
          );
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.startTime) {
          let yearTime: any = new Date(self.filters.startTime);
          yearTime.setFullYear(yearTime.getFullYear() + 1);
          yearTime = new Date(yearTime).getTime() - 86400000;
          return (
            new Date(self.filters.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime() 
            // ||time.getTime() > yearTime
          );
        } else {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2015-12-31").getTime()
          );
        }
      },
    };
  }
  /**
   * @description 重置
   */
  private reset() {
    this.filters.startTime = null;
    this.filters.endTime = null;
    this.filters.name = "";
    this.filters.type = "";
  }
  private makeSure() {
    if (!this.filters.name) {
      this.$message.warning("数据表格名称必填");
      return;
    }
    if (this.filters.name.length > 30) {
      this.$message.warning("数据表格名称不能超过30个字");
      return;
    }
    if (!this.filters.type) {
      this.$message.warning("请选择数据范围");
      return;
    }
    if (!this.filters.startTime && !this.filters.endTime) {
      this.$message.warning("请选择时间范围");
      return;
    }
    // this.$confirm(
    //   "数据导出中，您可切换或者关闭页面，导出进度和下载数据表格可至右上角导出中心。",
    //   "数据导出",
    //   {
    //     customClass: "commonConfirm",
    //     confirmButtonText: "跳转至下载中心查看",
    //     cancelButtonText: "返回",
    //   }
    // )
    //   .then(() => {
    //     this.$router.push("/main/export/export-result");
    //   })
    //   .catch(() => {
    //     this.$router.back();
    //   });
    const params = {
      开始时间: this.filters.startTime,
      结束时间: this.filters.endTime,
      range: this.filters.type,
      表格名称: this.filters.name,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    ExportHisData(this, params, loading)
      .then((data: any) => {
        this.$confirm(
          "数据导出中，您可切换或者关闭页面，导出进度和下载数据表格可至右上角导出中心。",
          "数据导出",
          {
            customClass: "commonConfirm",
            confirmButtonText: "跳转至下载中心查看",
            cancelButtonText: "返回",
          }
        )
          .then(() => {
            this.$router.push("/main/export/export-result");
          })
          .catch(() => {
            this.$router.back();
          });
      })
      .finally(() => {
        loading.close();
      });
  }
}
